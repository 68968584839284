import React from 'react';
import PlacementPage from './component/PlacementPage';


function App() {
    return (
        <div className="App">
          <PlacementPage/>
        </div>
    );
}

export default App;
